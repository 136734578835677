h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 36px;
  color: #000;
}
#blognav {
  margin-bottom: 40px;
}
.pod.podtop {
  border-top: 0px;
  border-radius: 0px 0px 8px 8px;
}
#closed {
  font-size: 0.85em;
  background: #e6e6e6;
  border-radius: 8px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 90px;
  padding: 30px;
  text-indent: 1.4em;
}

#closed p {
  margin: 0px;
  margin-bottom: 8px;
}
#sshproxycircle {
  background: #79fad5;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  text-align: center;
  display: flex;
  height: 90px;
  width: 90px;
  font-size: 22px;
  position: absolute;
  top: -35px;
  right: 55px;
  transform: rotate(20deg);
  z-index: -1;
}
#boiwrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#guidetitle {
  text-align: center;
  margin-bottom: 10px;
}
.tinyboi {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin: 5px;
  background: white;
  border-radius: 5px;
  width: 170px;
  text-align: center;
}
.fpico {
  width: 60px;
  height: 60px;
}
#finaid {
  max-width: 20%;
}
#pricewrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap
}
#ebbintro {
  font-style: italic;
  margin: auto;
  font-size: 0.8em;
}

#clientdg {
  max-width: 100%;
  margin: auto;
  display: block;
}

#ebbintro p {
  text-indent: 0em;
}

.blogicon {
  max-width: 30%;
  margin: auto;
  display: block;
}

.pod .double {
  padding: 20px 20px;
  box-shadow: 0px 3px 8px rgb(201, 201, 201);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.pod .double h3 {
  margin: 0px 0px;
}

.pod .double p {
  margin: 0px 0px;
}

#bigsub {
 margin-left: 20px;
}

.prose {
  text-indent: 2em;
}

.trial p {
  font-size: 1.3em;
  padding: 5px;
  background-color: #ddd;
  color: black;
  border-radius: 3px;
}

.iframe-container {
  overflow: hidden;
  margin: 10px 10px;
  padding-top: 20%;
  position: relative;
  max-width: 640px;
  max-height: 480px;
  min-height: 200px;
  margin: auto;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.fpzoneinner {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#usecase {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 0px;
}

#inactive {
  font-weight: bold;
  font-size: 1.1em;
}

.mini {
  width: 230px;
  margin-left: 20px;
  margin-right: 20px;
  height: 140px;
}

.minipodwrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.pod {
  padding: 0px 0px 20px 0px;
  background: white;
  margin-bottom: 20px;
  border-top-color: #79fad5;
  border-top-style: solid;
  border-top-width: 6px;
}

#signupbuttontop{
  padding: 0.3rem 0.5rem;
}

html,body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.05em;
  color: #000;
  margin: auto;
  overflow-y: scroll;
  background: #fff;
}
#hostname {
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
}

#body {
  flex: 1 0 auto;
}
details {
  margin-bottom: 10px;
}
#toc {
  margin-left: 30px;
}
#toc h3 {
  margin: 3px 0px;
}
#container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#red {
  color: red;
}
.content {
  max-width: 1080px;
  margin: auto;
}

a:link {
  text-decoration: none;
  color: #127fa6;
}

code> :hover {
  color: #000;
}
.whitebg {
  background-color: white;
}
.navright {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.navleft {
  align-items: center;
  display: flex;
}

.usecasegroup>div {
  margin: 20px 20px;
}

.usecasecard {
  border-top-color: #79fad5;
  border-top-style: solid;
  border-top-width: 6px;
}

.fpzone.smallinner .fpzoneinner {
    max-width: 200px;
    justify-content: flex-start;
}

#smallerbullets ul {
  list-style-type: none;
  /*use padding to move list item from left to right*/
  padding-left: 1em;
}

.white {
  color: white;
}

.white>a:visited {
  color: white;
}

.white>a:link {
  color: white;
}

.white>a:hover {
  color: #79fad5;
}

.white>code {
color: #000;
padding: 4px;
}

.white>code> :hover {
  color: #000;
}
#fpsshinst {
  margin: auto;
}
a:visited {
  color: #127fa6;
}
#fpbullets {
  text-align: left;
  font-weight: 600;
}
#fpbullets ul {
  list-style-type: none;
}

.smaller {
  font-size: 0.8em;
  max-width: 600px;
}

a:hover {
  color: #79fad5;
}

#footer {
  background-color: #FF7750;
  width: 100%;
  color: white;
  font-size: 1.06em;
  margin-top: 60px;
  flex-shrink: 0;
}

.smallbutton {
  font-size: 0.75em;
}

.rightbutton {
  align-self: flex-end;
}

.error {
  font-size: 1.2em;
  padding: 20px;
  background-color: #ff82aa;
  color: white;
}

.info {
  font-size: 1.2em;
  padding: 20px;
  background-color: #ddd;
  color: black;
}

#signuptop {
  border-color: #79fad5;
  border-style: solid;
  border-width: 3px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 20px;
}

.endpoint_type_option {
  background-color: #3ceeb9;
  margin: 10px 10px 10px 0px;
  padding: 3px 10px 5px 10px;
  border-radius: 8px;
}
#ore {
  vertical-align: middle;
}
.leftmostformcolumn {
  max-width: 250px;
  vertical-align: top;
}
.nob {
  border-top: 0px;
}
#underp {
  margin: 0px;
}
th,
td {
  padding: 4px;
  padding-right: 30px;
  text-align: left;
  border-top: 1px solid #ddd;
  vertical-align: top;
}

code {
  padding: 4px;
  border-radius: 3px;
  background-color: #eee;
}

.bigwrap {
  width: 100%;
}

.contentleftmargin {
  max-width: 1080px;
  margin: auto;
  padding: 0px 20px;
}

.dotgreen {
  height: 14px;
  width: 14px;
  background-color: #79fad5;
  border-radius: 50%;
  display: inline-block;
}
.dotyellow {
  height: 14px;
  width: 14px;
  background-color: #ffc75f;
  border-radius: 50%;
  display: inline-block;
}
#previewlink:hover {
  color: #ffc75f;
}
#pricesubtitle {
  margin-top: 60px;
  border-bottom-style: solid;
  border-bottom-color: #ff783f;
}
.dotred {
  height: 14px;
  width: 14px;
  background-color: #f0616d;
  border-radius: 50%;
  display: inline-block;
}

.formdesc {
  font-size: 12px;
  margin-left: 8px;
}

#pricebox {
  font-size: 1.1em;
  margin-top:30px;
  border-top-width: 12px;
}
.price {
  margin: auto;
  width: 300px;
  text-align: center;
}
#pitemlist {
  text-align:left;
  display: flex;
  flex-direction: column;
}
#pitemlist p {
  margin: 6px 0px;
}

#billingissue {
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}
#billingissue p {
  margin: 5px;
}

.cart {
  max-width: 320px;
}

#last4 {
  padding: 6px 6px;
}

.carttable {
  display: flex;
  flex-direction: column;
}

.cartrow {
  display: flex;
  justify-content: space-between;
}
.cartrow.important {
  margin-top: 15px;
}

.cartitem .big {
  font-size: 1.2em;
  font-weight: bold;
}

.cartprice {
  font-weight: bolder;
}

.smallertaxes {
  font-size: 0.8em;
}

.cartrow.single{
  display: flex;
  justify-content: flex-end;
}
#freetrial {
  font-size: 0.8em;
}
#nocc{
  margin-left: 4px;
  font-size: 0.6em;
}
#termdiv {
  display:flex;
  margin-bottom:30px;
}

#dollarprice {
  font-size: 5em;
  margin: 20px 0px;
}
#checkoutpriceboxwrapper {
  max-width: 100%;
}
#pricesub {
  padding-left: 50px;
  font-size: 1.0em;
  color: #444;
}
.pricetop {
  margin-bottom: 0px;
}
.warning {
  font-size: 1.2em;
  padding: 20px;
  background-color: #ffc75f;
  color: black;
}

.howworksection {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#howssh {
  margin-top: 80px;
}

#howworksdesc {
  max-width: 320px;
}

.howworksection img {
  max-width: 720px;
}

#paymentform {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 500px;
}

.success {
  padding: 20px;
  font-size: 1.2em;
  background-color: #6CE0BF;
}

#menusummary {
  display: none;
}

#outernav {
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: #ff783f;
}

#nav {
  display: flex;
  max-width: 1080px;
  margin: auto;
  justify-content: space-between;
  flex-wrap:wrap;
  align-items: center;
  font-size: 1.15em;
  font-weight: 1.2em;
  padding: 0px 20px;
}

#checkoutpricebox {
  font-size: 1.05em;
  margin-top: 0px;
  border-top-width: 12px;
  margin: unset;
}

#checkoutwrapper {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#checkoutwrapper > div {
  padding: 40px 0px;
}

#checkoutwrapper td {
  border-top: none;
  padding-right: 0px;
  vertical-align: middle;
}

.navinfowarning {
  display: flex;
  max-width: 310px;
  padding: 2px;
  font-size: 0.7em;
  line-height: 1em;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
}

.navinfowarning p {
  margin: 0px 0px;
  text-align: center;
}

#nav a:visited {
  color: black;
}
#nav a:link {
  color: black;
}
#nav a:hover {
  color: #79fad5;
}
.blacklink a:visited {
  color: black;
}
.blacklink a:link {
  color: black;
}
.blacklink a:hover {
  color: #79fad5;
}

#dateform label {
  margin-right: 4px;
}
#dateform input {
  margin-right: 16px;
}
#warnme {
  height: 24px;
  margin-top: 10px;
}
#dateformwrapper {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
#myChart {
  width: 500px;
  height: 400px;
}
#usageformdiv {
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
}

#usageformdiv div {
  margin-top: 10px;
}
#alldatawrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0px;
  justify-content: space-evenly;
}

#datatable td {
  max-width: 180px;
}

#consolenav a:visited {
  color: black;
}
#consolenav a:link {
  color: black;
}
#consolenav a:hover {
  color: #79fad5;
}
.nextto {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.marginright {
  margin-right: 20px;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#consolenav {
  display: flex;
  /* or inline-flex */
  justify-content: space-between;
  max-width: 1080px;
  margin: 0px auto 0px;
  flex-wrap: wrap;
}

#elems {
  flex-wrap: wrap;
}

.marginauto {
  margin: auto;
}

.textcenter {
  text-align: center;
}

.navitem {
  margin: 10px 26px 10px 0px;
}
.navitem.first {
  margin-left: 0px;
}
.navitem.last {
  margin-right: 0px;
}

.mono {
  font-family: 'Courier New', Courier, monospace;
}
/* #usagegraph {
  max-width: 500px;
  max-height: 400px;
}
 */

input[type="text"] {
border: 1px solid #ced4da;
border-radius: .25rem;
padding: 8px 10px;
line-height: 1.5;
}

input[type="password"] {
border: 1px solid #ced4da;
border-radius: .25rem;
padding: 8px 10px;
line-height: 1.5;
}
.billingtablee th {
  padding: 6px;
}
.billingtablee td {
  padding: 6px;
}
.billingtablee input {
  width: 90%;
}
#userthing {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  font-size: 0.7em;
  height: 35px;
  margin-top: 5px;
  margin-bottom: 5px;
}

input[type="submit"] {
  color: #3a3a3a;
  background-color: #79fad5;
  padding: .5rem 1rem;
  border-radius: 3px;
  font-size: 1.05em;
  border-style: none;
  box-shadow: 3px 3px;
}

#fpzonedouble {
  display: flex;
  flex-wrap: wrap;
}

.fpzone img {
  display: block;
  max-width: 380px;
  height: auto;
  flex-shrink: 0;
  margin: 20px 20px;
  padding: 0px 20px;
}

.fpzone h3 {
  margin: 0px 0px;
  font-size: 1.6em;
}

.grayback {
  background: #f2f2f2;
}

.fpzone.vert {
  flex-direction: column;
  text-align: center;
  padding: 0px 0px;
}

#mainimg {
  max-width: 550px;
  align-self: flex-end;
}
.fpimg {
  align-self:flex-start;
}
.fpzone {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  padding: 40px 0px;
}
#endpointvtable {
  font-size: 0.9em;
}
.fpzone p {
  margin: 8px 0px;
}
#ghactiondg {
  max-width: 700%;
}

button {
  color: #000;
  background-color: #79fad5;
  padding: .4rem 0.9rem;
  border-radius: 3px;
  border-style: none;
  font-size: 1.05em;
  box-shadow: 3px 3px;
}
input[type="submit"]:hover {
  background-color: #6ce0bf;
}
button:hover{
  background-color: #6ce0bf;
}
#lefty {
  text-align: left;
}
#trip {
  flex-wrap: unset;
}
.trip {
  font-size: 0.87em;
  padding: 20px;
  justify-content: flex-start;
}
.topalign {
  justify-content: flex-start;
}
#subtotalarea {
  display: flex;
}
#usecaseguides {
  padding: 0px;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
#usecaseguides>div {
  margin: 20px;
  width: 200px;
  justify-content: flex-start;
}

#cpnnote {
  font-size: 0.7em;
  font-style: italic;
}
.authdont {
  color: gray;
}
.authcomment {
  margin-left: 20px;
  font-size: 0.8em;
}
#paybutton {
  padding: 0.8rem 1.2rem;
}
#taxbutton {
  margin-top: 30px;
}
.taxes {
  font-size: 0.8em;
  width: 150px;
  text-align: right;
}
#addr {
  list-style: 1.2em;
}
#addr p {
  margin: 0px 0px;
}
#smallerterms {
  color: #444;
  font-size: 0.7em;
}
#totaltotalbox {
  display: flex;
}
#totaltotalbox div {
  margin: 0px 3px;
}
#finalprice {
  font-size: 1.2em;
  margin: 30px 0px 5px 0px;
}
#couponarea {
  margin: 20px 0px;
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#couponarea input {
  width: 120px;
}
.titlewithimg {
  display: flex;
}
.titlewithimg h3 {
  margin: 8px 0px 8px 8px;
}
#call {
  margin: auto;
  font-size: 1.2em;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}
#smallerterms ul {
  list-style: none;
  list-style-position:inside;
  margin-left: 0;
  padding-left: 0;
}
.indentleft {
  margin-left: 20px;
}
.lefttab {
  margin-left: 10px;
}

#smallerterms li {
  padding-left: 10px;
  text-indent: -10px;
}

pre code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 10px;
  margin: 0px;
  color: #000;
  border-radius: 4px;
  white-space: pre-wrap;
  word-break: break-all;
  max-width: max-content;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
#centeredcontent {
  margin: auto;
  max-width: 600px;
}

#bottomwrapper {
  display:flex;
  flex-wrap: wrap;
  max-width: 60%;
  justify-content: space-between;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.blogtext p {
  text-indent: 2em;
}
.blogtext {
  margin: auto;
  max-width: 660px;
  flex-direction: column;
  font-size: 1.0em;
}
.blogtext h2 {
  margin: 50px 0px 20px 0px;
}
.subp {
  max-width: 720px;
  margin: auto;
  margin-bottom: 24px;
}

.yellobg {
  background-color: #ffc75f;
}

#maintitle {
  margin-top: 50px;
  text-align: center;
}

#maintitle h1 {
  font-size: 3.5em;
  margin-bottom: 16px;
}

.ti {
  text-indent: 8%;
}

#previewbar {
  margin: auto;
  max-width: 900px;
}
/*
#f75900
#ff950d */

.animation2 {
  animation-name: example;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

#country {
  max-width: 80%;
}

#country select {
  width: 100%;
}

#imgright {
  width: 450px;

  /* background: white;
  border-radius: 4px;
  padding: 10px 10px; */
}

#fixedheight {
  table-layout: fixed;
}

#fixedheight tr td {
  padding: 0px 10px 0px 0px;
  height: 45px;
  vertical-align: middle;
}

#titletitle {
  font-size: 3.3em;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 25px;
}

#titlewithimg {
  display: flex;
  align-items: center;
  justify-content: center;
}

#titlewithimg img {
  width: 45px;
  margin: 5px;
  align-self: center;
}

.cardtitlewrapper {
  display: flex;
}
.cardtitlewrapper img {
  margin: 3px;
  width: 20px;
  align-self: center;
}

.ci {
  margin-right: 8px;
  width: 40px;
}
.cib {
  margin-right: 8px;
  width: 60px;
}
#demoboi div {
  margin-bottom: 5px;
}
#demoboi h2 {
  margin-top: 5px;
}
#demoboi {
  font-size: 0.75em;
  bottom: 21px;
  right: 21px;
  position: fixed;
  border-style: solid;
  max-width: 70%;
  background: white;
  display: flex;
  border-radius: 5px;
  padding: 8px;
  flex-direction: column;
  text-align: center;
}
#piserve {
  padding: 10px;
  width: 60%;
  display: block;
  margin: auto;
}
#pisetup {
  padding: 10px;
  width: 100%;
  display: block;
  margin: auto;
}
#keyadd {
  padding: 10px;
  width: 85%;
  margin: auto;
  display: block;
}
#piservers {
  padding: 10px;
  width: 65%;
  margin: auto;
  display: block;
}
.icorow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#guidewrap {
  max-width: 600px;
}
.center {
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -37.5px;
}
#map {
  max-width: 45%;
  align-self: center;
  margin: 0px;
}
#tinie {
  width: 32px;
  margin-right: 8px;
}
.carousel {
  min-width: 330px;
  text-align: center;
  line-height: 75px;
  height: 75px;
  padding: 10px;
}
.guidearticle {
  max-width: 800px;
  margin: auto;
}
.carousel .change_outer {
  top: 0;
  height: 75px;
  overflow: hidden;
}
.carousel .change_outer .change_inner {
  animation: rotate 10s ease-in-out infinite;
}
.carousel .change_outer .element {
  display: block;
}
@keyframes rotate {
  0%, 15% {
    transform: translateY(0);
  }
  18%, 33% {
    transform: translateY(-75px);
  }
  36%, 51% {
    transform: translateY(-150px);
  }
  54%, 69% {
    transform: translateY(-225px);
  }
  72%, 87% {
    transform: translateY(-300px);
  }
  89%, 99% {
    transform: translateY(-375px);
  }
  100% {
    transform: translateY(-450px);
  }
}

@media only screen and (max-width: 1100px) {
  #imgright {
    order: -1;
  }
  .subp {
    max-width: 600px;
  }
  .howworksection img {
    max-width: 600px;
  }
  #mainimg {
    max-width: 450px;
  }
  #guidewrap {
    max-width: 450px;
  }
}

.tinycreate {
  margin-left: 20px;
  font-size: 0.6em;
}

@media only screen and (max-width: 600px) {
  #rightmenu {
    justify-content: flex-end;
  }

  .fpzone.vert {
    flex-direction: row;
  }
  .pod {
    padding: unset;
  }
  .fpzone.smallinner .fpzoneinner {
    max-width: 85%;
  }
  .fpzone img {
    max-width: 90%;
  }
  #mainimg {
    max-width: 90%;
  }
  .tinyboi {
      width: 140px;
  }

  #checkoutpriceboxwrapper {
    order: -1;
  }

  input {
    max-width: 100%;
  }
  .howworksection img {
    max-width: 100%;
  }
  #bottomwrapper {
    max-width: 90%;
  }
  #promosub {
    font-size: 0.8em;
  }
  #maintitle h1 {
    font-size: 1.75em;
    margin-bottom: 16px;
  }
  #sshproxycircle {
    right: -10px;
    right: -10px;
    font-size: 0.9em;
    height: 70px;
    width: 70px;
  }
  #finaid {
    max-width: 70%;
  }
  #titletitle {
    font-size: 2.5em;
    flex-direction: column;
  }
  #trip {
    flex-wrap: wrap;
  }
  #demoboi {
    visibility: hidden;
  }
}
